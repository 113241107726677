import { DownloadURL } from "constants/download";
import React from "react";
import { getDeviceOS, OS } from "utils/detect-os";
import Redirect from "components/Redirect";

const AppDownload: React.FC = () => {
    const os = getDeviceOS();
    let to = "/";
    if (os === OS.Android) {
        to = DownloadURL.googlePlay;
    } else if (os === OS.IOS) {
        to = DownloadURL.appStore;
    }
    return <Redirect to={to} />;
};

export default AppDownload;
